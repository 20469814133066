require('dotenv').config()

module.exports = {
  siteMetadata: {
    title: 'GUD EF Design',
    description:
      'GUD 4.0 is all about our core: the shared DNA of the EF family. By following simple design guidelines, all of our distinct products can appear related at a glance.',
    author: 'EF Global Creative',
    url: 'https://gud.ef.design',
  },
  plugins: [
    {
      resolve: 'gatsby-source-storyblok',
      options: {
        accessToken: process.env.GATSBY_STORYBLOK_ACCESS_TOKEN,
        homeSlug: 'home',
        version:
          process.env.CONTENT_VERSION === 'production' ? 'published' : 'draft',
        resolveRelations: [
          'asset-card.asset',
          'related-item.item',
          'content-link.content',
          'content-link.asset',
        ],
        includeLinks: true,
      },
    },
    'gatsby-plugin-react-helmet',
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `images`,
        path: `${__dirname}/src/images`,
      },
    },
    'gatsby-transformer-sharp',
    'gatsby-plugin-sharp',
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: 'GUD EF Design',
        short_name: 'GUD EF Design',
        start_url: '/',
        background_color: '#fff',
        theme_color: '#191919',
        display: 'minimal-ui',
        icon: 'src/images/ef-logo.png', // This path is relative to the root of the site.
      },
    },
    `gatsby-plugin-layout`,
    {
      resolve: `@gatsby-contrib/gatsby-plugin-elasticlunr-search`,
      options: {
        // Fields to index
        fields: ['name', 'full_slug', 'content', 'product_slug'],
        // How to resolve each field`s value for a supported node type
        resolvers: {
          // For any node of type StoryblokEntry, list how to resolve the fields` values
          StoryblokEntry: {
            name: node => node.name,
            full_slug: node => {
              if (node && node.full_slug.startsWith('products/')) {
                const splittedFullSlug = node.full_slug.split('/')
                splittedFullSlug.shift()
                return splittedFullSlug.join('/')
              } else {
                return node.full_slug
              }
            },
            content: node => {
              //TODO: parse the story content and look for text fields to improve index file and avoid bloating
              //make sure to update the fields to index if necessary
              if (typeof node.content !== 'object') {
                return JSON.parse(node.content)
              }
              return node.content
            },
            product_slug: node => {
              if (node && node.full_slug.startsWith('products/')) {
                const storyParent = node.full_slug.match(/products\/(.+?)\/.*/)
                if (storyParent && storyParent.length === 2) {
                  return `${storyParent[1]}`
                }
              }
              return ''
            },
          },
        },
      },
    },
    {
      resolve: `gatsby-plugin-google-gtag`,
      options: {
        // You can add multiple tracking ids and a pageview event will be fired for all of them.
        trackingIds: [
          'UA-87282423-4', // Google Analytics / GA
        ],
        // This object gets passed directly to the gtag config command
        // This config will be shared accross all trackingIds
        gtagConfig: {
          //
        },
        // This object is used for configuration specific to this plugin
        pluginConfig: {
          //
        },
      },
    },
    'gatsby-plugin-sass',
    {
      resolve: `gatsby-plugin-nprogress`,
      options: {
        // Setting a color is optional.
        color: `#191919`,
        // Disable the loading spinner.
        showSpinner: false,
      },
    },
    // this (optional) plugin enables Progressive Web App + Offline functionality
    // To learn more, visit: https://gatsby.app/offline
    //'gatsby-plugin-offline',
  ],
}
